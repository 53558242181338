<template id="UploadFile">
    <div>
       
        <!-- <input type="hidden" :id="'filename_'+id" :value="fileName"> -->
        <input type="file" ref="file" @change="onFileChange" class="d-none" :accept="accept">
        <img :src="(setFile) ? setFile: G_ImgUrl('no_img.png')" class="zn-img-upload" alt="">

        <div style="text-align: center;margin-top: -15px;">
            <v-btn class="mx-1" fab dark x-small color="primary" @click="selectFile">
                <v-icon dark>
                        mdi-pencil
                    </v-icon>
                </v-btn>

                <v-btn class="mx-1" fab dark x-small color="red" v-if="fl" @click="removeFile">
                    <v-icon dark>
                            mdi-delete
                        </v-icon>
                </v-btn>
        </div>
        

    </div>
</template>
<script>
    export default {
    template: '#UploadFile',
    props: {
        file:null,
        accept: {
        type: String,
        default: 'image/*'
        },
    },
    data() {
        return {
            fileName: '',
            fl : null
        }
    },
    mounted(){
        this.fl = null;
    },
    computed: {
        setFile() {
             let setF = null;

            if (this.fl) {
                setF = this.fl;
            }else{
                setF = this.file;

            }
            
            return setF;
        }
    },
    methods: {
        selectFile(){
        this.$refs.file.click()
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            var fileExtension = files[0].name.split('.').pop();

            var maxSizeImg = 10 * (1024*1024);
            var extAllow = ["jpg", "jpeg", "png"];

            if (!files.length) {
                    return
                }

            if (files[0].size > maxSizeImg) {
                this.$snotify.warning('Ukuran File Terlalu Besar, Maksimal 10 Mb');
                return false;
            }else if(!extAllow.includes(files[0].name.split('.').pop())){
                this.$snotify.warning("File Tidak Sesuai, yang diperbolehkan hanya : "+ extAllow.join(', '));
                
                return false;
                
            }else{
            
                this.fileName = files[0].name
                this.createImage(files[0])
            }


       
        },
        createImage(file) {
        var image = new Image()
        var reader = new FileReader()

        reader.onload = (e) => {
            this.fl = e.target.result;

            // this.setFile(e.target.result);
            this.$emit('change', this.fl)
        }
        reader.readAsDataURL(file)
        },
        removeFile() {
            this.fl = ''
            this.fileName = ''
        }
    }
    }
</script>